import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import axios from "axios";
import ClipLoader from "react-spinners/ClipLoader";

import requests from "../../services/requests";
import Card from "../../components/Cards";
import Layout from "../../components/Layout";
import Paginate from "../../components/Paginate/Paginate";
import TitlePurple from "../../components/Typography/TitlePurple";
import TrackProcess from "../../components/TrackProcess";
import SEO from "../../components/SEO";
import Submenu from "../../components/Menus/Submenu";

const ProcessesPage = () => {
    const [load, setLoad] = useState(false);
    const [list, setList] = useState([]);
    const [cancelTokenSource, setCancelTokenSource] = useState(false);
    const [checkedBtnIgnore, setCheckedBtnIgnore] = useState(true);

    const queryParams = new URLSearchParams(window.location.search);
    // const beforeSearch = queryParams.get("search");

    // Filters
    const filterInformation = useSelector(state => state.filter.value);
    const searchPeriod = useSelector(state => state.period);
    const [searchString, setSearchString] = useState(window.localStorage.getItem("process-filter") ?? "");

    const onChangeSearchString = value => {
        let search = value.trim();
        if (search.length === 0 || search.length > 2) {
            setSearchString(search);
        }
    };

    const [processQty, setProcessQty] = useState({
        view: 0,
        total: 0,
        pages: 0,
        currentPage: 0,
    });

    const [action, setAction] = useState(1);
    const [meta, setMeta] = useState({
        current_page: 1,
    });

    const listProcess = () => {
        if (window.localStorage.getItem("process-filter")) {
            setSearchString(window.localStorage.getItem("process-filter"));
        }
        setLoad(true);

        if (cancelTokenSource) {
            cancelTokenSource.cancel("cancel");
        }

        var currentCancelToken = axios.CancelToken.source();
        setCancelTokenSource(currentCancelToken);

        if (searchPeriod?.startsAt != null || searchPeriod?.endsAt != null) {
            requests
                .listProcess(
                    {
                        search: searchString,
                        filters: filterInformation,
                        period: searchPeriod,
                        ignore: checkedBtnIgnore,
                    },
                    meta.current_page > 0 ? meta.current_page : 1,
                    currentCancelToken
                )
                .then(data => {
                    setList(data.data);

                    setMeta(data.meta);

                    setProcessQty({
                        view: data.meta.item_count,
                        total: data.meta.total_count,
                        currentPage: data.meta.current_page,
                        pages: data.meta.total_pages,
                    });

                    setCancelTokenSource(null);
                    setLoad(false);
                });
        }
    };

    useEffect(() => {
        listProcess();
    }, [action]);

    useEffect(() => {
        setMeta({
            current_page: 1,
        });
        listProcess();
    }, [filterInformation, searchString, searchPeriod, checkedBtnIgnore]);

    return (
        <Layout>
            <SEO title="Processos" />
            <Submenu
                processQty={processQty}
                onChangeSearchString={onChangeSearchString}
                type={{ filters: true, infos: true, search: true, btnIgnore: true, searchString: searchString }}
                checkedBtnIgnore={checkedBtnIgnore}
                setCheckedBtnIgnore={setCheckedBtnIgnore}
            />
            <div className="p-4">
                <Card>
                    <TitlePurple>Processos</TitlePurple>
                    {load ? (
                        <div className="flex flex-col items-center">
                            <ClipLoader size={20} loading={load} />
                        </div>
                    ) : (
                        <div className="border-t border-C2 border-solid pt-4 pb-12 overflow-hidden">
                            {list &&
                                list.map(process => (
                                    <TrackProcess
                                        key={process.identifier}
                                        id={process.identifier}
                                        process={process}
                                        listProcess={listProcess}
                                    />
                                ))}
                        </div>
                    )}

                    {meta.total_pages > 1 && (
                        <Paginate meta={meta} setMeta={setMeta} action={action} setAction={setAction} showDetails={true} />
                    )}
                </Card>
            </div>
        </Layout>
    );
};

export default ProcessesPage;
